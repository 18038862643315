<template>
  <div
    class="help-and-support"
    data-qa="help-and-support"
  >
    <h2
      class="help-and-support__header"
      v-html="headerText"
    />
    <div
      class="help-and-support__description"
      v-html="descriptionText"
    />

    <div class="help-and-support__ctrls">
      <BaseButton
        variant="outlined"
        class="help-and-support__cta"
        data-qa="help-and-support-mailto"
        :text="$t('eu__help_and_support__email_v9.5.0')"
        @click="handleEmailClick"
      />

      <BaseButton
        variant="outlined"
        class="help-and-support__cta"
        data-qa="help-and-support-call"
        :text="$t('eu__help_and_support__hotline_v9.5.0')"
        @click="handlePhoneCallClick"
      />

      <div class="help-and-support__office-hours">
        {{ phoneNumber }} / 09:30-17:00 Mo-Fr
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@shared/components/BaseButton.vue'
import Tracker from '@shared/Tracker'
import { useI18n } from 'petite-vue-i18n'

export default {
  name: 'HelpAndSupport',
  components: {
    BaseButton,
  },
  props: {
    header: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    mailTo: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      phoneNumber: '089 904 226 740',
    }
  },
  computed: {
    appName() {
      return this.t(`eu__generic_${import.meta.env.VITE_DISEASE}_app`)
    },
    rawPhoneNumber() {
      return this.phoneNumber.replace(/\s/g, '')
    },
    headerText() {
      if (!this.digaEnabled) {
        return this.t('eu__help_and_support__header__diga_disabled')
      }

      return this.t('eu__help_and_support__header_v9.5.0')
    },
    descriptionText() {
      if (!this.digaEnabled) {
        return this.t('eu__help_and_support__description__diga_disabled')
      }

      return this.t('eu__help_and_support__description_v9.5.0', {
        app_name: this.appName,
      })
    },
    digaEnabled() {
      return import.meta.env.VITE_DIGA_ENABLED === 'true'
    },
  },
  methods: {
    handlePhoneCallClick() {
      Tracker.trackKaiaEvent(
        {
          event_name: 'c.lfcycl.select_button',
          app_area: 'lifecycle',
          action: 'view',
          object_type: 'screen',
          source: 'web-checkout',
        },
        {
          custom_payload: {
            label: 'call_hotline',
          },
        },
      )

      const tag = document.createElement('a')
      tag.href = `tel:${this.rawPhoneNumber}`
      tag.click()
    },
    handleEmailClick(e) {
      Tracker.trackKaiaEvent(
        {
          event_name: 'c.lfcycl.select_button',
          app_area: 'lifecycle',
          action: 'view',
          object_type: 'screen',
          source: 'web-checkout',
        },
        {
          custom_payload: {
            label: 'write_email',
          },
        },
      )

      const tag = document.createElement('a')
      tag.href = this.mailTo
      tag.click()
    },
  },
}
</script>

<style lang="scss">
@import '@shared/components/css/HelpAndSupport';
</style>
