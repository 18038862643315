<template>
  <form
    class="email-check-content"
    @submit.prevent="checkEmailCode"
  >
    <MobileHeader />
    <div class="content">
      <CorporateHeader />
      <div class="content-header">
        <p
          class="kaia-p3-bold"
          v-html="$t('b2b_email_code_welcome_text', { name: corporateName })"
        />
      </div>
      <div class="instructions">
        <p v-html="$t('b2b_email_code_instructions_intro')" />
        <div class="step">
          <h4 v-html="$t('b2b_email_code_instructions_step_1_title')" />
          <p v-html="$t('b2b_email_code_instructions_step_1_text')" />
        </div>
        <div class="step">
          <h4 v-html="$t('b2b_email_code_instructions_step_2_title')" />
          <p v-html="$t('b2b_email_code_instructions_step_2_text')" />
        </div>
        <div class="step">
          <h4 v-html="$t('b2b_email_code_instructions_step_3_title')" />
          <p v-html="$t('b2b_email_code_instructions_step_3_text')" />
        </div>
      </div>
      <div class="email-check-action">
        <div class="email-check-field-group">
          <EmailField
            :default-value="emailCode"
            :show-errors="showErrors"
            :error-message="customErrorMessage"
            :autofocus="true"
            data-q-a="email"
            @input="handleTextInputChange"
          />
        </div>
        <div class="interactive-button-regular">
          <BaseButton
            :text="$t('b2b_email_code_submit')"
            :disabled="loadingButtonState === 'DISABLED'"
            data-qa="check-email-code"
            @click="checkEmailCode"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { useI18n } from 'petite-vue-i18n'
import { createHelpers } from '@shared/vuex-utils'

import ROUTE from '@msk-us/router/names'

import EmailField from '@shared/components/EmailField.vue'
import CorporateHeader from '@shared/components/CorporateHeader.vue'
import MobileHeader from '@shared/components/MobileHeader.vue'
import BaseButton from '@shared/components/BaseButton.vue'

const { mapFields } = createHelpers({
  getterType: 'b2b/getField',
  mutationType: 'b2b/updateField',
})

export default {
  name: 'B2BEmail',
  components: {
    MobileHeader,
    EmailField,
    CorporateHeader,
    BaseButton,
  },
  props: {
    configureStickyHeader: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      emailCodeState: false,
      customErrorMessage: '',
      showErrors: false,
      loadingButtonState: 'ACTIVE',
    }
  },
  computed: {
    ...mapFields(['formData.emailCode']),
    ...mapGetters('b2b', ['corporate', 'verificationParams']),
    corporateName() {
      return this.corporate.title || ''
    },
    isFormValid() {
      return this.emailCodeState
    },
  },
  created() {
    const { email: emailFromStore } = this.verificationParams
    if (this.voucher && this.voucher.emailCode) {
      this.emailCode = this.voucher.emailCode
    }
    if (this.$route.query.email) {
      this.emailCode = this.$route.query.email
    }
    if (this.$route.params?.emailCode) {
      this.emailCode = this.$route.params.emailCode
    }
    if (emailFromStore) {
      this.emailCode = emailFromStore
    }

    if (this.emailCode) {
      this.checkEmailCode()
    }
  },
  methods: {
    ...mapActions('b2b', ['checkEmail']),
    redirectToNext(voucher) {
      this.loadingButtonState = 'ACTIVE'
      this.$router.push({
        name: ROUTE.B2B_SIGN_UP,
        params: {
          corp_name: voucher.corporate_name,
        },
      })
    },
    checkEmailCode(e) {
      if (e) {
        e.preventDefault()
      }
      this.showErrors = false

      if (!this.isFormValid) {
        this.showErrors = true
        return false
      }

      this.loadingButtonState = 'PROCESSING'

      this.checkEmail({ email: this.emailCode, corporate: this.corporate.key })
        .then(this.redirectToNext)
        .catch((error) => {
          this.loadingButtonState = 'ACTIVE'
          if (error.response && error.response.status === 403) {
            this.showErrors = true
            this.customErrorMessage = this.t(
              'b2b_email_code_not_valid_with_support_email',
              {
                VUE_APP_SUPPORT_EMAIL: import.meta.env.VITE_SUPPORT_EMAIL,
              },
            )
          }
        })
    },
    handleTextInputChange({ name, value, state }) {
      this.customErrorMessage = ''

      switch (name) {
        case 'email':
          this.emailCode = value
          this.emailCodeState = state
          break

        default:
          break
      }
    },
  },
}
</script>

<style lang="scss">
@import './css/B2BEmail';
</style>
