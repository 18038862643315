import AbstractModel from './utils/abstractModel'
import CurrentSubscription from './currentSubscription'
import UserProfile from './userProfile'
import types from './utils/types'

class User extends AbstractModel {
  /**
   * @type {integer}
   */
  id

  /**
   * @type {CurrentSubscription, undefined}
   */
  currentSubscription

  /**
   * @type {string}
   */
  email

  /**
   * @type {string}
   */
  guid

  /**
   * @type {UserProfile}
   */
  profile

  constructor(json) {
    super(json)
    this.id = json.id
    this.currentSubscription =
      json.current_subscription &&
      new CurrentSubscription(json.current_subscription)
    this.email = json.email
    this.guid = json.guid
    this.profile = json.profile && new UserProfile(json.profile)
    this.validate()
  }

  validate() {
    this.assertType('id', [types.NUMBER])
    this.assertType('currentSubscription', [CurrentSubscription, types.NULL])
    this.assertType('email', [types.STRING])
    this.assertType('guid', [types.STRING])
    this.assertType('profile', [UserProfile])
  }

  /**
   * Indicate whether user is Pro
   * @readonly
   * @type {boolean}
   */
  get isPro() {
    return this.currentSubscription?.isExpired === false
  }
}

export default User
