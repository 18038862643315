<template>
  <transition
    name="vertical-show-hide"
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
  >
    <div
      v-if="visible"
      class="vertical-show-hide__inner"
    >
      <slot />
    </div>
  </transition>
</template>
<script setup>
defineProps({
  visible: Boolean,
})

const beforeEnter = (el) => {
  el.style.height = '0'
}

const enter = (el) => {
  const height = el.scrollHeight
  el.style.height = height + 'px'
}

const leave = (el) => {
  el.style.height = el.scrollHeight + 'px'
  requestAnimationFrame(() => {
    el.style.height = '0'
  })
}
</script>

<style lang="scss">
.vertical-show-hide-enter-active,
.vertical-show-hide-leave-active {
  // give it a bit of a delay so that clicks are still executed with the old bounding boxes
  transition: height 0.2s ease-in-out 0.1s;
}

.vertical-show-hide-enter,
.vertical-show-hide-leave-to {
  height: 0;
}

.vertical-show-hide__inner {
  overflow: hidden;
}
</style>
